body {
  background: #0d0d0d;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;
}

h1 {
  margin: 0;
}

.tooltip {
  background: #111;
  border-radius: 5px;
  max-width: 200px;
  padding: 5px;
}

.divider {
  margin: 10px -10px;
  border-bottom: 2px solid #0d0d0d;
}
